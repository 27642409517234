@if (isBusy) {
	<div class="image-loading">
		<div translate="UPLOADING"></div>
		<fm-loader [type]="'ellipsis'"></fm-loader>
	</div>
}

@if (!isBusy) {
	<div class="edit-link">
		<label for="cdnUpload" translate="UPLOAD_IMAGE"></label>
		<input
			type="file"
			id="cdnUpload"
			style="display: none"
			accept="image/*"
			(change)="uploadImages($event)" />
		<div class="note"><span translate="UPLOAD_IMAGE_MESSAGE"></span></div>
	</div>
}
