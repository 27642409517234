<h3 translate="FILTER_BY_PARTNER"></h3>
<div class="filter-wrap">
	@if (partners.length <= LIST_LIMIT) {
		<input
			type="text"
			class="fm-input"
			[(ngModel)]="qs"
			placeholder="{{ 'SEARCH' | translate }}" />

		<ul class="partnerfilter-options">
			<li
				class="fm-partnerfilter select-all"
				(click)="selectAll()"
				[ngClass]="{ active: filter === 'all' }">
				<span translate="ALL_PARTNERS"></span>
			</li>

			@for (partner of filterList(list, qs); track partner.id) {
				<li class="fm-partnerfilter" [ngClass]="{ active: isActive(partner) }">
					<div class="partner-item" (click)="setItem(partner)">
						<profile-image
							[objtype]="ProfileType.COMPANY"
							[obj]="partner"
							mode="size_20"></profile-image>
						<span [translate]="partner?.name"></span>
					</div>

					@if (isActive(partner)) {
						<div class="partner-remove">
							<i class="fa fa-times" (click)="clearItem(partner)"></i>
						</div>
					}
				</li>
			}
		</ul>
	}

	@if (partners.length > LIST_LIMIT) {
		<div class="typeahead">
			<div class="field-editor">
				<input
					type="text"
					class="fm-input"
					[(ngModel)]="qs"
					placeholder="{{ 'SEARCH' | translate }}"
					(ngModelChange)="getList(qs)" />
				<div class="typeahead-loader" *ngIf="isLoading">
					<span class="fa fa-circle-notch fa-spin"></span>
				</div>
			</div>

			@if (list.length) {
				<ul class="filter-list">
					<li
						class="fm-partnerfilter"
						*ngFor="let partner of list; trackBy: 'index' | trackByProperty">
						<div class="partner-item" (click)="setItem(partner)">
							<profile-image
								[objtype]="ProfileType.COMPANY"
								[obj]="partner"
								mode="size_20"></profile-image>
							<span [translate]="partner.name"></span>
						</div>

						@if (isActive(partner)) {
							<div class="partner-remove">
								<i class="fa fa-times" (click)="clearItem(partner)"></i>
							</div>
						}
					</li>
				</ul>
			}

			@if (noResults && qs) {
				<div class="no-results" translate="NO_RESULTS"></div>
			}

			<div class="partner-counts">{{ filteredCount }} of {{ totalCount }} Partners</div>
		</div>
	}
</div>
