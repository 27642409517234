import { Injectable } from '@angular/core';

import { ApiService, GlobalService } from '@fm/services';
import { CompanySetting } from '@fmlib/interfaces';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserSetting } from './usersettings.service';
import { DataType } from '@fmlib/enums';

@Injectable()
export class SettingsService {
	private global;

	constructor(
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	formatSetting(item: CompanySetting): CompanySetting {
		switch (item.datatype) {
			case DataType.BOOLEAN:
				if (typeof item.value === 'string') {
					item.value = item.value === 'true';
				} else return item.value;
				break;
			case DataType.NUMBER:
				item.value = Number(item.value);
				break;
			case 'api':
			case DataType.JSON:
				try {
					item.value = JSON.parse(item.value);
				} catch (err) {
					console.warn('error : parsing companysettings json', item.key);
				}
				break;
			default:
		}

		return item;
	}

	getCompanySettings(): Observable<CompanySetting[]> {
		const params = {
			sort: 'order',
			per_page: 200,
		};

		return this.FM_Api.get('companysettings', params).pipe(
			catchError(() => {
				return EMPTY;
			}),
			map((data) => {
				data.forEach((item) => this.formatSetting(item));
				return data;
			}),
			tap((data) => {
				this.global.companysettings = data;
			})
		);
	}

	getUserSettings(): Observable<UserSetting[]> {
		const params = {
			sort: 'name',
			per_page: 200,
			conditions: JSON.stringify({ adminUser: this.global.user.id }),
		};

		return this.FM_Api.get('usersettings', params).pipe(
			catchError(() => {
				return EMPTY;
			}),
			tap((data) => {
				this.global.usersettings = data;
			})
		);
	}
}
